<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-solution-hero.svg') + ')'}">
      <div class="container space-top-3 space-bottom-2 position-relative">
        <div class="row justify-content-lg-between align-items-lg-center">
          <div class="col-md-6">
            <div class="mb-5 mb-md-0">
              <span class="d-block small font-weight-bold text-cap mb-2">our solutions</span>
              <h1>API Management Platform</h1>
            </div>
          </div>

          <div class="col-md-6">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-management.svg">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Overveiw Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="row justify-content-lg-between">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <span class="d-block small font-weight-bold text-cap mb-2">Overview</span>
          <h2 class="mb-3">Why API Management?</h2>
        </div>
        <div class="col-lg-6">
          <div class="media mb-3">
            <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
              <i class="fas fa-check" />
            </span>
            <div class="media-body">
              <p>With growing number of external &amp; internal APIs, effective API Management helps manage, secure, mediate, analyze your complex enterprise API programme.</p>
            </div>
          </div>
          <div class="media mb-3">
            <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
              <i class="fas fa-check" />
            </span>
            <div class="media-body">
              <p>Senstitve data interchange cannot be avoided in modern digital economy, your APIs &amp; data need to be secured and protected from unauthorized access and threats.</p>
            </div>
          </div>
          <div class="media mb-3">
            <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
              <i class="fas fa-check" />
            </span>
            <div class="media-body">
              <p>API developer experience (DX) matters in building up API ecosystem, proper API management enables intuitive &amp; self-served experience to scale.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Overview Section ========== -->

    <!-- ========== API Management Section ========== -->
    <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">API Enables Programmable Enterprises</span>
          <h2>Assemble Modern Digital Businesses in Weeks via API Platform</h2>
        </div>

        <img class="img-fluid" src="@/assets/svg/illustrations/api-management-apis.svg">

        <div class="card">
          <div class="card-body">
            <div class="w-md-80 text-center mx-auto py-6">
              <h2>Interested in Adopting API Platform?</h2>
            </div>

            <div class="row">
              <div class="col-md-6 mb-4 mb-lg-0">
                <div class="card shadow-none h-100 text-center">
                  <div class="card-body">
                    <div class="max-w-10rem mx-auto my-4">
                      <img class="img-fluid" src="@/assets/svg/icons/icon-68.svg">
                    </div>
                    <h3>Build with beNovelty</h3>
                    <p>Join Securities and Futures Commission (SFC), Jebsen Group, Jardine Restaurant Group, AS Watson Group etc. to build your Enterprise API Platform with us.</p>
                  </div>
                  <div class="card-footer border-0 pt-0 pb-6">
                    <a id="ga-solution-apimanagement-build-with-benovelty-button" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm" class="font-weight-bold">Contact Us <i class="fas fa-angle-right fa-sm ml-1" /></a>
                    <span class="small d-block mt-2">Powered by beNovelty's API Consultants</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6 mb-4 mb-lg-0">
                <div class="card shadow-none h-100 text-center">
                  <div class="card-body">
                    <div class="max-w-10rem mx-auto my-4">
                      <img class="img-fluid" src="@/assets/svg/icons/icon-40.svg">
                    </div>
                    <h3>Subscribe our Platform</h3>
                    <p>Join developers from growing tech companies and startups by subscribing our Managed Cloud API Platform to bootstrap your own API business.</p>
                  </div>
                  <div class="card-footer border-0 pt-0 pb-6">
                    <a id="ga-solution-apimanagement-subscribe-our-platform-button" href="javascript:;" data-toggle="modal" data-target="#benGeneralForm" class="font-weight-bold">Contact Us <i class="fas fa-angle-right fa-sm ml-1" /></a>
                    <span class="small d-block mt-2">Powered by <img class="img-fluid max-w-15rem" src="@/assets/svg/logos/logo-oah.svg"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-80 mx-auto d-none d-md-block">
          <img class="img-fluid" src="@/assets/svg/illustrations/api-management-platforms.svg">
        </div>

        <div class="d-md-none">
          <img class="img-fluid d-block" src="@/assets/svg/illustrations/api-management-connectors-mobile.svg">
          <div class="card">
            <div class="card-body">
              <img class="img-fluid d-block" src="@/assets/svg/illustrations/api-management-platforms-mobile.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End API Management Section ========== -->

    <!-- ==========  CTA Section ==========  -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-80 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">you're in good company</span>
        <h2>Powering Innovative Enterprises</h2>
      </div>
      <PartnerCarousel :items="api_partner_list" />
      <div class="mt-4">
        <ContactUsBar :detail="{ title: 'Interested in Partnering with Us?' }" />
      </div>
    </div>
    <!-- ========== End CTA Section ==========  -->

    <!-- ========== More Solutions Section ==========-->
    <div
      class="space-2 space-lg-3 bg-blue-light"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">More by beNovelty</span>
        <h2>Want to Know More About API Economy?</h2>
      </div>
      <div class="position-relative">
        <div class="container">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-lg-none&quot;,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 5000,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-4.svg">
                  <h4>API Consultancy</h4>
                  <p>Read more to find out what makes beNovelty different in API consultancy.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-consultancy-button" href="/solutions/api-consultancy"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-consultancy-button" :to="{ path: '/solutions/api-consultancy' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-28.svg">
                  <h4>API Product</h4>
                  <p>Find out the 3 simple steps for building API-as-a-Products.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-product-button" href="/solutions/api-product"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-product-button" :to="{ path: '/solutions/api-product' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <!-- <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-3.svg">
                  <h4>API Management</h4>
                  <p>Learn how to manage, secure, mediate, analyze complex enterprise API programme with API Management.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <a id="ga-solutions-more-by-benovelty-api-management-button" href="/solutions/api-management">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></a>
                </div>
              </div>
            </div> -->
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-69.svg">
                  <h4>API Community</h4>
                  <p>Discover more on our latest API community events.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-community-button" href="/solutions/api-community"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-community-button" :to="{ path: '/solutions/api-community' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <!-- Hubspot Form - Provider Modal -->
    <!-- <div id="providerForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="providerFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="providerFormTitle" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div> -->
    <!-- Body -->
    <!-- <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit"> -->

    <!--[if lte IE 8]>
                  <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
                  <![endif]-->

    <!-- <form ref="providerForm" /> -->

    <!-- <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js" />
              <script>
                hbspt.forms.create({
                portalId: "7091000",
                formId: "f09151ed-974f-40a3-a96a-8ff19e1754f3"
                });
              </script> -->

    <!-- </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Hubspot Form - Provider Modal -->
    <!-- ========== End Popup Modals ========== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import PartnerCarousel from '@/components/partner-carousel'
import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.slick-carousel.js'
import '../../../assets/vendor/slick-carousel/slick/slick.js'
import { mapGetters } from 'vuex'
export default {
  name: 'ApiManagement',
  components: {
    PartnerCarousel,
    ContactUsBar
  },
  data() {
    return {
      api_partner_list: []
    }
  },
  computed: {
    ...mapGetters([
      'partners'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    // this.loadScriptProviderForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "f09151ed-974f-40a3-a96a-8ff19e1754f3"
    //     });
    //   `
    //   this.$refs.providerForm.appendChild(secondScript)
    // })
  },
  created() {
    this.api_partner_list = this.$store.getters.partners.api_partner_list
  },
  // methods: {
  //   loadScriptProviderForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.providerForm.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'API Management | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/api-management' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'API Management | Solutions | beNovelty' },
        { property: 'og:description', content: 'API Management Platform for companies to unlock API business integration with lower budgets and shorter timeline.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/api-management' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
</style>
